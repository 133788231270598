import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`For time:`}</p>
    <p>{`10 Bear Complex (95/65, p. clean, f. squat, p. press, b. squat, p. press
off back)`}</p>
    <p>{`20 Pistols (10/leg, alternating)`}</p>
    <p>{`30 T2B`}</p>
    <p>{`40 Med Ball Situps (20/14`}{`#`}{`)`}</p>
    <p>{`50 Wall Balls (20/14`}{`#`}{`)`}</p>
    <p>{`60ft OH Walking Lunge (50/35`}{`#`}{` DB, 30ft right arm, 30ft left arm)`}</p>
    <p>{`:70 Plank hold`}</p>
    <p>{`80 Air Squats`}</p>
    <p>{`90 Double Unders`}</p>
    <p>{`100ft Backward Sled Pull (On Turf, Sled + 135/90`}{`#`}{`)`}</p>
    <p>{`Run 1/2 block to front door.`}</p>
    <p><strong parentName="p">{`*`}{`This Saturday is our free class at 9:00 & 10:00am so bring a
friend!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      